import { AxiosRequestHeaders } from "axios";


export default function authHeader(): AxiosRequestHeaders | null {
  const userItem = localStorage.getItem('user');
  if (!userItem) {
    return null;
  }

  const user = JSON.parse(userItem);
  if (user && user.accessToken !== undefined) {
    return { Authorization: 'Bearer ' + user.accessToken } as AxiosRequestHeaders;
  }

  return null;
}