import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, CardFooter, Button, Typography, Input, Alert } from "@material-tailwind/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import ReactSelect from "react-select";
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FIELD_REQUIRED } from "../../utils/form-field-invalid-messages.constants";
import { ExchangeResponse } from "../../services/response/get-exchange.response";
import { UpdateExchangeDto, getExchange, updateExchange } from "../../services/exchange.service";
import { ExchangeState } from "../../enums/exchange-state";
import { PaymentOrderState } from "../../enums/payment-order-state";
import { FundsReceiptState } from "../../enums/funds-receipt-state.enum";

interface PaymentOrderFormValues {
  state: PaymentOrderState | null;
  fundsReceiptState: FundsReceiptState | null;
}

interface FormValues {
  reference: string;
  state: ExchangeState | null;
  sendPaymentOrder: PaymentOrderFormValues;
  receivePaymentOrder: PaymentOrderFormValues;
}


export function EditTransaction() {
  const [exchange, setExchange] = useState<ExchangeResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      setErrorMessage('L\'identifiant de la transaction est manquant.');
      return;
    };

    const fetchExchange = async () => {
      try {
        const getExchangeResponse = await getExchange(id);
        setExchange(getExchangeResponse);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération des informations de la transaction.';
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
      }
    };
    fetchExchange();
  }, [id]);
  
  const exchangeStateOptions = Object.values(ExchangeState).map((state) => ({
    value: state,
    label: state,
  }));

  const paymentOrderStateOptions = Object.values(PaymentOrderState).map((state) => ({
    value: state,
    label: state,
  }));

  const paymentOrderFundsReceiptStateOptions = Object.values(FundsReceiptState).map((state) => ({
    value: state,
    label: state,
  }));

  const initialValues: FormValues = {
    reference: exchange?.reference || '',
    state: exchange?.state as ExchangeState || null,
    sendPaymentOrder: {
      state : exchange?.sendPaymentOrder?.state as PaymentOrderState || null,
      fundsReceiptState: exchange?.sendPaymentOrder?.fundsReceiptState as FundsReceiptState || null,
    },
    receivePaymentOrder: {
      state : exchange?.receivePaymentOrder?.state as PaymentOrderState || null,
      fundsReceiptState: exchange?.receivePaymentOrder?.failedAt as FundsReceiptState || null,
    },
  };

  const handleSubmit = async (values: FormValues) => {
    const {  state, sendPaymentOrder, receivePaymentOrder } = values;
  
    const updateExchangeDto: UpdateExchangeDto = {
      state: state as ExchangeState,
      sendPaymentOrder: {
        state: sendPaymentOrder.state as PaymentOrderState,
        fundsReceiptState: sendPaymentOrder.fundsReceiptState as FundsReceiptState,
      },
      receivePaymentOrder: {
        state: receivePaymentOrder.state  as PaymentOrderState,
        fundsReceiptState: receivePaymentOrder.fundsReceiptState as FundsReceiptState,
      },
    };

    try {
      await updateExchange(exchange?.id as string ,updateExchangeDto);
      setShowNotification(true);
    } catch (error) {
      let errorMsg = 'Une erreur est survenue lors de la mise à jour des informations de la transaction .'
      if (error instanceof AxiosError && error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
        errorMsg = error?.response?.data?.message;
      }
      setErrorMessage(errorMsg);
    }
  };

  const validationSchema = Yup.object({
    reference: Yup.string().required(FIELD_REQUIRED),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <CardHeader variant="gradient" color="amber" className="mb-4 p-6">
            <Typography variant="h5" color="white" className="text-center">
              Modifier la transaction
            </Typography>
          </CardHeader>
          <CardBody>
            <Alert
              color="green"
              show={showNotification}
              className="mb-4"
              dismissible={{
                onClose: () => setShowNotification(false),
              }}
            >
              La transaction a été mis à jour avec succès.
            </Alert>
     
            <div className="w-full max-w-[30rem] mx-auto flex flex-col gap-4">

              <div>
                <label htmlFor="reference" className="text-sm font-medium text-gray-600">
                  Numéro
                </label>
                <Input
                  id="reference"
                  type="reference"
                  label="Reference"
                  color="amber"
                  size="lg"
                  disabled={true}
                  {...formik.getFieldProps("reference")}
                />
                {formik.touched.reference && formik.errors.reference ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.reference}</div>
                ) : null}
              </div>

              <div>
                <label htmlFor="state" className="text-sm font-medium text-gray-600">
                  Status
                </label>

                <ReactSelect
                  id="state"
                  options={exchangeStateOptions}
                  value={exchangeStateOptions.find(
                    (option) => option.value === formik.values.state
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("state", selectedOption?.value)
                  }
                  isSearchable={false}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.state}</div>
                ) : null}
              </div>

              <Typography variant="h6" className="text-center mb-2 mt-4">
                Envoi des fonds
              </Typography>
              <hr className="mb-4" />

              <div>
                <label htmlFor="sendPaymentOrder.state" className="text-sm font-medium text-gray-600">
                  Status
                </label>

                <ReactSelect
                  id="sendPaymentOrder.state"
                  options={paymentOrderStateOptions}
                  value={paymentOrderStateOptions.find(
                    (option) => option.value === formik.values.sendPaymentOrder.state
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("sendPaymentOrder.state", selectedOption?.value)
                  }
                  isSearchable={false}
                />
                {formik.touched.sendPaymentOrder?.state && formik.errors.sendPaymentOrder?.state ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.sendPaymentOrder?.state}</div>
                ) : null}
              </div>

              <div>
                <label htmlFor="sendPaymentOrder.fundsReceiptState" className="text-sm font-medium text-gray-600">
                  Status de traitement des fonds
                </label>

                <ReactSelect
                  id="sendPaymentOrder.fundsReceiptState"
                  options={paymentOrderFundsReceiptStateOptions}
                  value={paymentOrderFundsReceiptStateOptions.find(
                    (option) => option.value === formik.values.sendPaymentOrder.fundsReceiptState
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("sendPaymentOrder.fundsReceiptState", selectedOption?.value)
                  }
                  isSearchable={false}
                />
                {formik.touched.sendPaymentOrder?.fundsReceiptState && formik.errors.sendPaymentOrder?.fundsReceiptState ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.sendPaymentOrder?.fundsReceiptState}</div>
                ) : null}
              </div>

              <Typography variant="h6" className="text-center mb-2 mt-4">
                Reception des fonds
              </Typography>
              <hr className="mb-4" />

              <div>
                <label htmlFor="receivePaymentOrder.state" className="text-sm font-medium text-gray-600">
                  Status
                </label>

                <ReactSelect
                  id="receivePaymentOrderState"
                  options={paymentOrderStateOptions}
                  value={paymentOrderStateOptions.find(
                    (option) => option.value === formik.values.receivePaymentOrder.state
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("receivePaymentOrder.state", selectedOption?.value)
                  }
                  isSearchable={false}
                />
                {formik.touched.receivePaymentOrder?.state && formik.errors.receivePaymentOrder?.state ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.receivePaymentOrder?.state}</div>
                ) : null}
              </div>

              <div>
                <label htmlFor="receivePaymentOrder.fundsReceiptState" className="text-sm font-medium text-gray-600">
                  Status de traitement des fonds
                </label>

                <ReactSelect
                  id="receivePaymentOrder.fundsReceiptState"
                  options={paymentOrderFundsReceiptStateOptions}
                  value={paymentOrderFundsReceiptStateOptions.find(
                    (option) => option.value === formik.values.receivePaymentOrder.fundsReceiptState
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("receivePaymentOrder.fundsReceiptState", selectedOption?.value)
                  }
                  isSearchable={false}
                />
                {formik.touched.receivePaymentOrder?.fundsReceiptState && formik.errors.receivePaymentOrder?.fundsReceiptState ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.receivePaymentOrder?.fundsReceiptState}</div>
                ) : null}
              </div>

            </div>
            
            {errorMessage && (
              <div className="text-base text-center text-red-500">
                {errorMessage}
              </div>
            )}
          </CardBody>
          <CardFooter>
            <div className="flex justify-center w-full">
              <Button
                color="amber"
                size="lg"
                type="submit"
                //onClick={() => handleSubmit(formik.values)}
                className="mr-4 text-white"
              >
                Enregistrer
              </Button>
              <Button
                color="gray"
                size="lg"
                onClick={() => navigate(`/transactions/${id}/show`)}
              >
                Annuler
              </Button>
            </div>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}

export default EditTransaction;