import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, CardFooter, Button, Typography, Input, Alert } from "@material-tailwind/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserRegistrationProgressRequest, UserProfileRequest, getUser, updateUser, PhoneNumber } from "../../services/user.service";
import { getCountries } from "../../services/country.service";
import { GetUserResponse } from "../../services/response/get-user.response";
import { AxiosError } from "axios";
import { CountryResponse } from "../../services/response/country.response";
import ReactSelect from "react-select";
import PhoneNumberField, { PhoneNumberCountry } from "../../components/PhoneNumberField";
import 'react-phone-input-2/lib/style.css';
import fr from 'react-phone-input-2/lang/fr.json'
import { Gender } from "../../enums/gender.enum";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BIRTHDATE_DATE_INVALID, FIELD_REQUIRED, PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_MAX_LENGTH_REQUIRED, PHONE_NUMBER_MIN_LENGTH, PHONE_NUMBER_MIN_LENGTH_REQUIRED } from "../../utils/form-field-invalid-messages.constants";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  nationalityCountryId: string;
  phoneNumberValue: string,
  phoneNumberCountry: PhoneNumberCountry | null,
  gender: string,
  birthDate: Date | null;
  addressCountryId: string;
  active: boolean;
  isEmailVerified: boolean;
  isProfilePartOneCompleted: boolean;
  isProfilePartTwoCompleted : boolean;
  isKycCompleted: boolean; 
}

const GenderLabels = {
  [Gender.FEMALE]: 'femme',
  [Gender.MALE]: 'homme',
  [Gender.OTHER]: 'autre',
};

export function EditUser() {
  const [user, setUser] = useState<GetUserResponse | null>(null);
  const [countries, setCountries] = useState<CountryResponse[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesResponse = await getCountries();
        setCountries(countriesResponse);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération des informations de l\'utilisateur.';
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!id) {
      setErrorMessage('L\'identifiant de l\'utilisateur est manquant.');
      return;
    };

    const fetchUser = async () => {
      try {
        const getUserResponse = await getUser(id);
        setUser(getUserResponse);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération des informations de l\'utilisateur.';
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
      }
    };
    fetchUser();
  }, [id]);

  const getCountryOptions = (countries: CountryResponse[] | null) => {
    return countries?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];
  };

  const phoneNumberCountryInitialValue = {
    countryCode: user?.userProfile?.phoneNumberCountry.isoCode2,
  } as PhoneNumberCountry;
  
  const initialValues: FormValues = {
    email: user?.email  || '',
    firstName: user?.userProfile?.firstName || '',
    lastName: user?.userProfile?.lastName || '',
    active: user?.active === true,
    nationalityCountryId: user?.userProfile?.nationalityCountry.id || '',
    phoneNumberValue: user?.userProfile?.phoneNumberValue || '',
    phoneNumberCountry: phoneNumberCountryInitialValue,
    gender: user?.userProfile?.gender || '',
    birthDate: user?.userProfile?.birthdate ? new Date(user?.userProfile?.birthdate) : null,
    addressCountryId: user?.userProfile?.userAddress.country.id || '',
    isEmailVerified: user?.userRegistrationProgress?.isEmailVerified === true,
    isProfilePartOneCompleted: user?.userRegistrationProgress?.isProfilePartOneCompleted === true,
    isProfilePartTwoCompleted : user?.userRegistrationProgress?.isProfilePartTwoCompleted === true,
    isKycCompleted: user?.userRegistrationProgress?.isKycCompleted === true,
  };

  const handleSubmit = async (values: FormValues) => {
    const { 
      email, firstName, lastName, active, nationalityCountryId, phoneNumberValue, phoneNumberCountry, 
      gender, birthDate, addressCountryId, isEmailVerified, isProfilePartOneCompleted, isProfilePartTwoCompleted, 
      isKycCompleted 
    } = values;
    
    const phoneNumber: PhoneNumber = {
      value: phoneNumberValue,
      countryCode: phoneNumberCountry?.countryCode ?? null,
    };

    const userProfileRequest: UserProfileRequest = {
      email,
      firstName,
      lastName,
      nationalityCountryId,
      phoneNumber,
      gender,
      birthDate,
      addressCountryId,
      active,
    };

    const userRegistrationProgress: UserRegistrationProgressRequest = {
      isEmailVerified,
      isProfilePartOneCompleted,
      isProfilePartTwoCompleted,
      isKycCompleted,
    };

    try {
      await updateUser(user?.id as string, userProfileRequest, userRegistrationProgress);
      setShowNotification(true);
    } catch (error) {
      let errorMsg = 'Une erreur est survenue lors de la mise à jour des informations de l\'utilisateur .'
      if (error instanceof AxiosError && error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
        errorMsg = error?.response?.data?.message;
      }
      setErrorMessage(errorMsg);
    }
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(FIELD_REQUIRED),
    lastName: Yup.string().required(FIELD_REQUIRED),
    active: Yup.boolean(),
    gender: Yup.mixed<Gender>().oneOf(Object.values(Gender)).required(FIELD_REQUIRED),
    nationalityCountryId: Yup.string().required(FIELD_REQUIRED),
    birthdate: Yup.date().typeError(BIRTHDATE_DATE_INVALID).required(FIELD_REQUIRED),
    addressCountryId: Yup.string().required(FIELD_REQUIRED),
    phoneNumberValue: Yup.string().required(FIELD_REQUIRED)
      .min(PHONE_NUMBER_MIN_LENGTH, PHONE_NUMBER_MIN_LENGTH_REQUIRED)
      .max(PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_MAX_LENGTH_REQUIRED),
    phoneNumberCountry: Yup.object().shape({
      countryCode: Yup.string().required(FIELD_REQUIRED),
    }),
    isEmailVerified: Yup.boolean(),
    isProfilePartOneCompleted: Yup.boolean(),
    isProfilePartTwoCompleted : Yup.boolean(),
    isKycCompleted: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <CardHeader variant="gradient" color="amber" className="mb-4 p-6">
            <Typography variant="h5" color="white" className="text-center">
              Modifier l'utilisateur
            </Typography>
          </CardHeader>
          <CardBody>
            <Alert
              color="green"
              show={showNotification}
              className="mb-4"
              dismissible={{
                onClose: () => setShowNotification(false),
              }}
            >
              L'utilisateur a été mis à jour avec succès.
             </Alert>
     
            <div className="w-full max-w-[30rem] mx-auto flex flex-col gap-4">
               <Typography variant="h6" className="text-center mb-2">
                Profile
              </Typography>
                <hr className="mb-4" />
                <div>
                  <label htmlFor="active" className="text-sm text-gray-600 mr-4">
                    Actif
                  </label>
                  <input
                    id="active"
                    type="checkbox"
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                    className="mt-2 text-amber-600 rounded h-4 w-4"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="text-sm font-medium text-gray-600">
                    Adresse e-mail
                  </label>
                  <Input
                    id="email"
                    type="email"
                    label="Adresse e-mail"
                    color="amber"
                    size="lg"
                    disabled={true}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div>
                  <Input
                    id="lastName"
                    label="Nom"
                    size="lg"
                    color="amber"
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.lastName}</div>
                  ) : null}
                </div>

                <div>
                  <Input
                    id="firstName"
                    label="Prénom"
                    size="lg"
                    color="amber"
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.firstName}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="nationalityCountryId" className="block text-sm font-medium text-gray-700">
                    Pays de la nationalité
                  </label>
                  <ReactSelect
                    id="nationalityCountryId"
                    options={getCountryOptions(countries)}
                    value={getCountryOptions(countries).find((option) => option.value === formik.values.nationalityCountryId)}
                    onChange={(selectedOption) => formik.setFieldValue("nationalityCountryId", selectedOption?.value)}
                    onBlur={() => formik.setFieldTouched("nationalityCountryId")}
                  />
                  {formik.touched.nationalityCountryId && formik.errors.nationalityCountryId ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.nationalityCountryId}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="phoneNumberValue" className="block text-sm font-medium text-gray-700">
                    Numéro de téléphone
                  </label>
                  <PhoneNumberField
                    name="phoneNumberValue"
                    inputClass="form-input !w-full text-base"
                    field={{ ...formik.getFieldProps("phoneNumberValue") }}
                    form={formik}
                    localization={fr}
                    searchPlaceholder="Rechercher un pays"
                    searchNotFodund="Aucun pays trouvé"
                    enableSearch={true}
                    autocompleteSearch={true}
                    regions={'africa'}
                    preferredCountries={['ci', 'bj', 'sn', 'tg', 'ml', 'bf', 'gn', 'ne', 'cm', 'cg', 'cd', 'td']}
                    // TODO: add mask from cm
                    masks={{ ci: '.. .. .. .. ..', bj: '.. .. .. ..',  gn: '... .. .. ..', ml: '.. .. .. ..', ne: '.. .. .. ..', sn: '.. ... .. ..', tg: '.. .. .. ..' }}
                  />
                  {formik.touched.phoneNumberValue && formik.errors.phoneNumberValue ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.phoneNumberValue}</div>
                  ) : null}
                </div>

                <div>
                  <span className="block text-sm font-medium text-gray-700">Genre</span>
                  <div className="mt-2">
                    {Object.entries(GenderLabels).map(([gender, label], index) => (
                      <label key={gender} className={`inline-flex items-center ${index > 0 ? 'ml-6' : ''}`}>
                        <input
                          type="radio"
                          name="gender"
                          value={gender}
                          checked={formik.values.gender === gender}
                          onChange={formik.handleChange}
                          className="text-amber-600"
                        />
                        <span className="ml-2 text-gray-700">{label}</span>
                      </label>
                    ))}
                  </div>
                  {formik.touched.gender && formik.errors.gender ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.gender}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="birthDate" className="block text-sm font-medium text-gray-700">
                    Date de naissance
                  </label>
                  <DatePicker
                    id="birthDate"
                    selected={formik.values.birthDate}
                    onChange={(date: Date | [Date | null, Date | null] | null) =>
                      formik.setFieldValue("birthDate", date)
                    }
                    onBlur={() => formik.setFieldTouched("birthDate")}
                    dateFormat="dd/MM/yyyy"
                    className="form-input w-full text-base border-gray-300 border h-10"
                    locale="fr"
                    showYearDropdown
                    dropdownMode="select"
                    showMonthDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    placeholderText="JJ/MM/AAAA"
                    maxDate={new Date()}
                    openToDate={new Date("01/01/2000")}
                  />
                  {formik.touched.birthDate && formik.errors.birthDate ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.birthDate}</div>
                  ) : null}
              </div>

              <div>
                  <label htmlFor="addressCountryId" className="block text-sm font-medium text-gray-700">
                    Pays de résidence
                  </label>
                  <ReactSelect
                    id="addressCountryId"
                    options={getCountryOptions(countries)}
                    value={getCountryOptions(countries).find((option) => option.value === formik.values.addressCountryId)}
                    onChange={(selectedOption) => formik.setFieldValue("addressCountryId", selectedOption?.value)}
                    onBlur={() => formik.setFieldTouched("addressCountryId")}
                  />
                  {formik.touched.addressCountryId && formik.errors.addressCountryId ? (
                    <div className="text-xs text-red-500 mt-1">{formik.errors.addressCountryId}</div>
                  ) : null}
                </div>

              <Typography variant="h6" className="text-center mb-2 mt-4">
                Statut de l'inscription
              </Typography>
              <hr className="mb-4" />
              
              <div>
                <label htmlFor="isEmailVerified" className="text-sm text-gray-600 mr-4">
                  Email vérifié
                </label>
                <input
                  id="isEmailVerified"
                  type="checkbox"
                  checked={formik.values.isEmailVerified}
                  onChange={formik.handleChange}
                  className="mt-2 text-amber-600 rounded h-4 w-4"
                />
              </div>
              <div>
                <label htmlFor="isProfilePartOneCompleted" className="text-sm text-gray-600 mr-4">
                  Profil partie 1 complétée
                </label>
                <input
                  id="isProfilePartOneCompleted"
                  type="checkbox"
                  checked={formik.values.isProfilePartOneCompleted}
                  onChange={formik.handleChange}
                  className="mt-2 text-amber-600 rounded h-4 w-4"
                />
              </div>
              <div>
                <label htmlFor="isProfilePartTwoCompleted" className="text-sm text-gray-600 mr-4">
                  Profil partie 2 complétée
                </label>
                <input
                  id="isProfilePartTwoCompleted"
                  type="checkbox"
                  checked={formik.values.isProfilePartTwoCompleted}
                  onChange={formik.handleChange}
                  className="mt-2 text-amber-600 rounded h-4 w-4"
                />
              </div>
              <div>
                <label htmlFor="isKycCompleted" className="text-sm text-gray-600 mr-4">
                  KYC complété
                </label>
                <input
                  id="isKycCompleted"
                  type="checkbox"
                  checked={formik.values.isKycCompleted}
                  onChange={formik.handleChange}
                  className="mt-2 text-amber-600 rounded h-4 w-4"
                />
              </div>

            </div>
            {errorMessage && (
              <div className="text-base text-center text-red-500">
                {errorMessage}
              </div>
            )}
          </CardBody>
          <CardFooter>
            <div className="flex justify-center w-full">
              <Button
                color="amber"
                size="lg"
                type="submit"
                onClick={() => handleSubmit(formik.values)}
                className="mr-4 text-white"
              >
                Enregistrer
              </Button>
              <Button
                color="gray"
                size="lg"
                onClick={() => navigate(`/users/${id}/show`)}
              >
                Annuler
              </Button>
            </div>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}

export default EditUser;