import axios from "axios";
import authHeader from "../utils/helpers/auth-header";
import { ExchangeResponse } from "./response/get-exchange.response";
import { ExchangeState } from "../enums/exchange-state";
import { PaymentOrderState } from "../enums/payment-order-state";
import { FundsReceiptState } from "../enums/funds-receipt-state.enum";

const exchangesApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/exchanges`;

export interface UpdateExchangePaymentOrderDto {
  state: PaymentOrderState;
  fundsReceiptState?: FundsReceiptState;
}

export interface UpdateExchangeDto {
  state: ExchangeState;
  sendPaymentOrder: UpdateExchangePaymentOrderDto;
  receivePaymentOrder: UpdateExchangePaymentOrderDto;
}

export const getExchanges = async (): Promise<ExchangeResponse[]> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.get(`${exchangesApiUrl}`, { headers: authHeaderData });

  return exchanges.data;
}

export const getExchange = async (id: string): Promise<ExchangeResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchange = await axios.get(`${exchangesApiUrl}/admin/${id}`, { headers: authHeaderData });

  return exchange.data;
}

export const updateExchange = async (id: string, updateExchangeDto: UpdateExchangeDto) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.post(`${exchangesApiUrl}/${id}`, updateExchangeDto, { headers: authHeaderData });

  return exchanges.data;
}
