import { Gender } from "../../enums/gender.enum"

export const getGenderLabel = (gender: string) => {
  if (gender === Gender.FEMALE) {
    return 'Femme';
  }

  if (gender === Gender.MALE) {
    return 'Homme';
  }

  if (gender === Gender.OTHER) {
    return 'Autre';
  }

  throw new Error(`Gender ${gender} not handled`);
}