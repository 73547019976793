import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Chip,
  Button,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { GetUserResponse } from "../../services/response/get-user.response";
import { GetUsersParams, UserFilter, UserRole, getUsers } from "../../services/user.service";
import { AxiosError } from "axios";
import { ITEMS_PER_PAGE_DEFAULT } from "../../utils/table.constant";
import { formatDateTime } from "../../utils/helpers/date.helpers";
import { DataGrid, GridColDef, GridPaginationModel, GridToolbar } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { PencilIcon, EyeIcon } from "@heroicons/react/24/solid";
import { KycVerificationStatus } from "../../enums/kyc-verification-status.enum";
import { colors } from "@material-tailwind/react/types/generic";

export function ListUsers() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [usersTableData, setUsersTableData] = useState<GetUserResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true)
      try {
        const getUsersParams: GetUsersParams = {
          filter: {
            role: UserRole.USER,
          },
        };

        console.log('getUsersParams', getUsersParams);

        const getUsersResponse = await getUsers(getUsersParams);
        setUsersTableData(getUsersResponse);
        setTotalItems(getUsersResponse.length);
        setLoading(false);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération de la liste des utilisateurs.'
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setCurrentPage(newModel.page);
  };

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Nom",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.userProfile?.firstName ?? ''} ${params.row.userProfile?.lastName ?? ''}`,
    },
    {
      field: "kycVerificationStatus",
      headerName: "Status de verification KYC",
      flex: 1,
      valueGetter: (params) =>
      `${params.row.userKycInfo?.verificationStatus}`,
      renderCell: (params) => {
        const kycVerificationStatus = params.value !== 'undefined' ? params.value : KycVerificationStatus.NOT_STARTED;
        let color = "blue-gray";
        switch (params.value) {
          case KycVerificationStatus.PENDING:
            color = "orange";
            break;
          case KycVerificationStatus.APPROVED:
            color = "green";
            break;
          case KycVerificationStatus.REJECTED:
            color = "red";
            break;
          default:
            color = "blue-gray";
            break;
        }

        return (<Chip
          variant="gradient"
          color={color as colors}
          value={kycVerificationStatus}
          className="py-0.5 px-2 text-[11px] font-medium"
        />)
      },
    },
    {
      field: "country",
      headerName: "Pays de résidence",
      flex: 1,
      valueGetter: (params) =>
        params.row.userProfile?.userAddress?.country?.name,  
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      flex: 1,
      valueFormatter: (params) => formatDateTime(params.value as string),
    },
    {
      field: "editAction",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 120,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <>
            <Link to={`/users/${id}/edit`}>
              <Button size="sm" variant="outlined" color="amber" className="py-1 px-2 flex items-center gap-1">
                <PencilIcon className="h-4 w-4" /> Modifier
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "showAction",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <>
             <Link to={`/users/${id}/show`}>
              <Button size="sm" variant="outlined" color="amber" className="py-1 px-2 flex items-center gap-1">
                <EyeIcon className="h-4 w-4" /> Voir
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="amber" className="mb-8 p-6">
          <Typography variant="h5" color="white" className="text-center">
            Liste des utilisateurs
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {errorMessage && (
            <div className="text-base text-center text-red-500">
              {errorMessage}
            </div>
          )}
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
              }}
              sortingOrder={['desc', 'asc']}
              rows={usersTableData}
              columns={columns}
              autoHeight={true}
              paginationModel={{ page: currentPage, pageSize: ITEMS_PER_PAGE_DEFAULT }}
              pageSizeOptions={[25, 50, 100]}
              rowCount={totalItems}
              pagination
              paginationMode="client"
              onPaginationModelChange={handlePaginationModelChange}
              loading={loading}
              localeText={{ noRowsLabel: "Il n'y a aucuns utilisateurs" }}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}


export default ListUsers;
