import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button,
  Chip,
} from '@material-tailwind/react';
import { AxiosError } from 'axios';
import { getUser } from '../../services/user.service';
import { GetUserResponse } from '../../services/response/get-user.response';
import { formatDate, formatDateTime } from '../../utils/helpers/date.helpers';
import { getGenderLabel } from '../../utils/helpers/get-gender-label';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { PencilIcon, TableCellsIcon } from '@heroicons/react/24/solid';
import { ExchangeResponse } from '../../services/response/get-exchange.response';
import { getExchange } from '../../services/exchange.service';
import { ExchangeState } from '../../enums/exchange-state';
import { colors } from '@material-tailwind/react/types/generic';
import { PaymentOrderState } from '../../enums/payment-order-state';
import { FundsReceiptState } from '../../enums/funds-receipt-state.enum';

export function ShowTransaction() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [exchange, setExchange] = useState<ExchangeResponse | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      setErrorMessage('L\'identifiant de la transaction est manquant.');
      return;
    };

    const fetchExchange = async () => {
      try {
        const getExchangeResponse = await getExchange(id);
        setExchange(getExchangeResponse);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération des informations sur la transaction.';
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
      }
    };

    fetchExchange();
  }, [id]);

  let colorState;
  switch (exchange?.state) {
    case ExchangeState.Pending:
      colorState = "orange";
      break;
    case ExchangeState.PartiallyCompleted:
      colorState = "lime";
      break;
    case ExchangeState.Completed:
      colorState = "green";
      break;
    case ExchangeState.Canceled:
      colorState = "gray";
      break;
    case ExchangeState.Failed:
      colorState = "red";
      break;
    default:
      colorState = "blue-gray";
      break;
  }

  let sendPaymentOrderState;
  switch (exchange?.sendPaymentOrder?.state) {
    case PaymentOrderState.Pending:
      sendPaymentOrderState = "orange";
      break;
    case ExchangeState.Completed:
      sendPaymentOrderState = "green";
      break;
    case ExchangeState.Canceled:
      sendPaymentOrderState = "gray";
      break;
    case ExchangeState.Failed:
      sendPaymentOrderState = "red";
      break;
    default:
      sendPaymentOrderState = "blue-gray";
      break;
  }

  let sendPaymentOrderFundsReceiptState;
  switch (exchange?.sendPaymentOrder?.fundsReceiptState) {
    case FundsReceiptState.NotReceived:
      sendPaymentOrderFundsReceiptState = "orange";
      break;
    case FundsReceiptState.Received:
      sendPaymentOrderFundsReceiptState = "green";
      break;
    case FundsReceiptState.Insufficient:
      sendPaymentOrderFundsReceiptState = "red";
      break;
    default:
      sendPaymentOrderFundsReceiptState = "blue-gray";
      break;
  }

  let receivePaymentOrderState;
  switch (exchange?.receivePaymentOrder?.state) {
    case PaymentOrderState.Pending:
      receivePaymentOrderState = "orange";
      break;
    case PaymentOrderState.Completed:
      receivePaymentOrderState = "green";
      break;
    case PaymentOrderState.Canceled:
      receivePaymentOrderState = "gray";
      break;
    case PaymentOrderState.Failed:
      receivePaymentOrderState = "red";
      break;
    default:
      receivePaymentOrderState = "blue-gray";
      break;
  }

  let receivePaymentOrderFundsReceiptState;
  switch (exchange?.receivePaymentOrder?.fundsReceiptState) {
    case FundsReceiptState.NotReceived:
      receivePaymentOrderFundsReceiptState = "orange";
      break;
    case FundsReceiptState.Received:
      receivePaymentOrderFundsReceiptState = "green";
      break;
    case FundsReceiptState.Insufficient:
      receivePaymentOrderFundsReceiptState = "red";
      break;
    default:
      receivePaymentOrderFundsReceiptState = "blue-gray";
      break;
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="amber" className="mb-4 p-6">
          <Typography variant="h5" color="white" className="text-center">
            Détails de la transaction
          </Typography>
        </CardHeader>
        <CardBody className="px-0 pt-0 pb-2">
          <div className="flex justify-end gap-4 mb-4">
            <Button
              color="amber"
              size="sm"
              variant="outlined"
              className="py-1 px-2 flex items-center gap-1"
              onClick={() => {
                navigate(`/transactions/${id}/edit`);
              }}
            >
              <PencilIcon className="h-4 w-4" />Modifier la transaction
            </Button>
          </div>
          {errorMessage && (
            <div className="text-base text-center text-red-500">
              {errorMessage}
            </div>
          )}
          <Tabs value="general">
            <TabsHeader>
              <Tab key="general" value="general">
                Informations générales
              </Tab>
              <Tab key="send-payment" value="send-payment">
                Données sur l'envoi des fonds
              </Tab>
              <Tab key="receive-payment" value="receive-payment">
                Données sur la réception des fonds
              </Tab>
            </TabsHeader>
            <TabsBody>
              <TabPanel key="general" value="general">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Numéro de transaction:
                    </Typography>
                    <Typography>{exchange?.reference}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Status:
                    </Typography>
                    <Typography>
                      <Chip
                        variant="gradient"
                        color={colorState as colors}
                        value={exchange?.state as string}
                        className="py-0.5 px-2 text-[11px] font-medium"
                      />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Date de création:
                    </Typography>
                    <Typography>{formatDateTime(exchange?.createdAt as string)}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Utilisateur:
                    </Typography>
                    <Typography>
                      <Link to={`/users/${exchange?.user?.id}/show`} className="text-amber-600 hover:underline">
                        {exchange?.user?.email}
                      </Link>
                    </Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                     Taux de change:
                    </Typography>
                    <Typography>1 {exchange?.receivePaymentOrder.paymentMethod.currencyCode} = {exchange?.receivePaymentOrder.receiveCurrencyToSendCurrencyRate} {exchange?.sendPaymentOrder.paymentMethod.currencyCode}
                    </Typography>
                  </div>

                </div>
              </TabPanel>
              <TabPanel key="send-payment" value="send-payment">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Méthode de payment:
                    </Typography>
                    <Typography>{exchange?.sendPaymentOrder.paymentMethod.paymentType.name}</Typography>
                  </div>
          
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                     Montant:
                    </Typography>
                    <Typography>{exchange?.sendPaymentOrder.amount} {exchange?.sendPaymentOrder.currency}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Status:
                    </Typography>
                    <Typography>
                      <Chip
                          variant="gradient"
                          color={sendPaymentOrderState as colors}
                          value={exchange?.sendPaymentOrder?.state as string}
                          className="py-0.5 px-2 text-[11px] font-medium"
                        />
                    </Typography>
                  </div>
  
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Status de réception des fonds:
                    </Typography>
                    <Typography>
                      <Chip
                        variant="gradient"
                        color={sendPaymentOrderFundsReceiptState as colors}
                        value={exchange?.sendPaymentOrder?.fundsReceiptState as string}
                        className="py-0.5 px-2 text-[11px] font-medium"
                      />
                    </Typography>
                  </div>

                  {exchange?.sendPaymentOrder.paidAt && (
                    <div className="w-full lg:w-1/2 mb-4">
                      <Typography className="font-semibold">
                        Date de paiement:
                      </Typography>
                      <Typography>{formatDateTime(exchange?.sendPaymentOrder.paidAt)}</Typography>
                    </div>
                  )}

                  {exchange?.sendPaymentOrder.failedAt && (
                    <div className="w-full lg:w-1/2 mb-4">
                      <Typography className="font-semibold">
                        Date du paiement en échec:
                      </Typography>
                      <Typography>{formatDateTime(exchange?.sendPaymentOrder.failedAt)}</Typography>
                    </div>
                  )}

                </div>
              </TabPanel>
              <TabPanel key="receive-payment" value="receive-payment">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Méthode de payment:
                    </Typography>
                    <Typography>{exchange?.receivePaymentOrder.paymentMethod.paymentType.name}</Typography>
                  </div>
          
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                     Montant:
                    </Typography>
                    <Typography>{exchange?.receivePaymentOrder.amount} {exchange?.receivePaymentOrder.currency}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Status:
                    </Typography>
                    <Typography>
                      <Chip
                          variant="gradient"
                          color={receivePaymentOrderState as colors}
                          value={exchange?.receivePaymentOrder?.state as string}
                          className="py-0.5 px-2 text-[11px] font-medium"
                        />
                    </Typography>
                  </div>
  
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Status de réception des fonds:
                    </Typography>
                    <Typography>
                      <Chip
                        variant="gradient"
                        color={receivePaymentOrderFundsReceiptState as colors}
                        value={exchange?.receivePaymentOrder?.fundsReceiptState as string}
                        className="py-0.5 px-2 text-[11px] font-medium"
                      />
                    </Typography>
                  </div>

                  {exchange?.receivePaymentOrder.paidAt && (
                    <div className="w-full lg:w-1/2 mb-4">
                      <Typography className="font-semibold">
                        Date de paiement:
                      </Typography>
                      <Typography>{formatDateTime(exchange?.receivePaymentOrder.paidAt)}</Typography>
                    </div>
                  )}

                  {exchange?.receivePaymentOrder?.state === PaymentOrderState.Failed && exchange?.receivePaymentOrder.failedAt && (
                    <div className="w-full lg:w-1/2 mb-4">
                      <Typography className="font-semibold">
                        Date du paiement en échec:
                      </Typography>
                      <Typography>{formatDateTime(exchange?.receivePaymentOrder.failedAt)}</Typography>
                    </div>
                  )}

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                     Informations de réception:
                    </Typography>
                    <Typography>{exchange?.receivePaymentOrder.userReceiptInfo}</Typography>
                  </div>

                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </CardBody>
      </Card>
    </div>
  );
}

export default ShowTransaction;
