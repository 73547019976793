import axios from "axios";
import authHeader from "../utils/helpers/auth-header";
import { GetUserResponse } from "./response/get-user.response";

const usersApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/users`;

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export interface UserFilter {
  // user
  id?: string;
  email?: string;
  role?: UserRole;
  createdAtBegin?: string;
  createdAtEnd?: string;

  // user profile
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  nationalityCountryId?: string;

  // user registration progress
  isEmailVerified?: boolean;
  isKycCompleted?: boolean;

  // user address
  userAddressCountryId?: string;
}

export interface GetUsersParams {
  filter?: UserFilter;
}

export interface UserProfileRequest {
  email: string;
  firstName: string;
  lastName: string;
  nationalityCountryId: string;
  phoneNumber: PhoneNumber,
  gender: string,
  birthDate: Date | null;
  addressCountryId: string;
  active: boolean;
}

export interface UserRegistrationProgressRequest {
  isEmailVerified: boolean;
  isProfilePartOneCompleted: boolean;
  isProfilePartTwoCompleted : boolean;
  isKycCompleted: boolean; 
}

export interface PhoneNumber {
  value: string;
  countryCode: string | null;
}

export const getUsers = async (getUsersParams?: GetUsersParams): Promise<GetUserResponse[]> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const users = await axios.get(`${usersApiUrl}`, { headers: authHeaderData, params: getUsersParams });

  return users.data;
}

export const getUser = async (id: string): Promise<GetUserResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const users = await axios.get(`${usersApiUrl}/${id}`, { headers: authHeaderData });

  return users.data;
}

export const updateUser = async (id: string, userProfileRequest: UserProfileRequest, userRegistrationProgressRequest: UserRegistrationProgressRequest): Promise<GetUserResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const users = await axios.post(`${usersApiUrl}/${id}`, { profile: userProfileRequest, registrationProgress: userRegistrationProgressRequest }, { headers: authHeaderData });

  return users.data;
}
