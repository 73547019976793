import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Dashboard, Transactions, Users } from "../src/layouts";
import { Login, SignUp, Logout } from "./pages/auth";
import { useEffect } from "react";
import { isTokenExpired } from "./utils/helpers/is-token-expired";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  const navigate = useNavigate();

  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : {};

  const accessToken = user?.accessToken;

  const isLoggedIn = accessToken !== undefined;
 
  useEffect(() => {
    if (accessToken && isTokenExpired(accessToken)) {
      navigate('/login');
    }
  }, [accessToken, navigate]);

  return (
    <Routes>
      <Route path="/dashboard/*"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Dashboard />
          </ProtectedRoute>
        } 
      />
      <Route path="/users/*"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route path="/transactions/*"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Transactions />
          </ProtectedRoute>
        }
      />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="/dashboard/home" replace />} />
    </Routes>
  );
}

export default App;
