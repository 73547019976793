import axios from "axios";
import authHeader from "../utils/helpers/auth-header";
import { CountryResponse } from "./response/country.response";

const usersApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/countries`;

export const getCountries = async (): Promise<CountryResponse[]> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const countries = await axios.get(`${usersApiUrl}`, { headers: authHeaderData });

  return countries.data;
}
