import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Button,
  Typography,
} from "@material-tailwind/react";
import * as Yup from 'yup';
import { EMAIL_INVALID, FIELD_REQUIRED, INVALID_PASSWORD_FORMAT, PASSWORD_FORMAT_REGEX, PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH_REQUIRED, PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_REQUIRED } from "../../utils/form-field-invalid-messages.constants";
import { Form, Formik, useFormik } from "formik";
import { signUp } from "../../services/auth.service";
import { useState } from "react";
import { AxiosError } from "axios";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export function SignUp() {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  const initialValues: FormValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  };

  const handleSubmit = async (values: FormValues) => {
    const { firstName, lastName, email, password } = values;

    try {
      await signUp({ firstName, lastName, email, password });
      navigate('/auth/login');
    } catch (error) {
      let errorMsg = 'Une erreur est survenue lors de la création de votre compte.'
      if (error instanceof AxiosError && error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
        errorMsg = error?.response?.data?.message;
      }
      setErrorMessage(errorMsg);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(EMAIL_INVALID).required(FIELD_REQUIRED),
    firstName: Yup.string().required(FIELD_REQUIRED),
    lastName: Yup.string().required(FIELD_REQUIRED),
    password: Yup.string().required(FIELD_REQUIRED)
    .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_REQUIRED)
    .max(PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH_REQUIRED)
    .matches(PASSWORD_FORMAT_REGEX, INVALID_PASSWORD_FORMAT),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <img
        src="https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
        className="absolute inset-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
      <div className="container mx-auto p-4">
        <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
          <form onSubmit={formik.handleSubmit}>
            <CardHeader
              variant="gradient"
              color="amber"
              className="mb-4 grid h-28 place-items-center"
            >
              <Typography variant="h3" color="white">
                Créer un compte
              </Typography>
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <div>
                <Input id="lastName" label="Nom" size="lg" color="amber" {...formik.getFieldProps('lastName')} />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.lastName}</div>
                ) : null}
              </div>

              <div>
                <Input id="firstName" label="Prénom" size="lg" color="amber" {...formik.getFieldProps('firstName')} />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.firstName}</div>
                ) : null}
              </div>
              
              <div>
                <Input id="email" type="email" label="Adresse e-mail" color="amber" size="lg" {...formik.getFieldProps('email')} />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.email}</div>
                ) : null}
              </div>
              
              <div>
                <Input id="password" type="password" label="Mot de passe" color="amber" size="lg" {...formik.getFieldProps('password')} />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-xs text-red-500 mt-1">{formik.errors.password}</div>
                ) : null}
              </div>

              <div className="text-xm text-red-500">{errorMessage}</div>  
              
            </CardBody>
            <CardFooter className="pt-0">
              <Button variant="gradient" fullWidth type="submit" color="amber" className="text-white">
                Créer un compte
              </Button>
              <Typography variant="small" className="mt-6 flex justify-center">
                Vous avez déjà un compte?
                <Link to="/login">
                  <Typography
                    as="span"
                    variant="small"
                    color="amber"
                    className="ml-1 font-bold"
                  >
                    Se connecter
                  </Typography>
                </Link>
              </Typography>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
}

export default SignUp;
