import { Navigate, Outlet } from "react-router-dom";


interface ProtectedRouteProps {
  isLoggedIn: boolean;
  redirectPath?: string;
  children: JSX.Element;
}

function ProtectedRoute({
  isLoggedIn,
  redirectPath = '/login',
  children,
}: ProtectedRouteProps): JSX.Element {
  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;