import {
  Bars4Icon,
  EyeIcon,
  HomeIcon,
  PencilIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import { Home } from "../src/pages/dashboard";
import { ListUsers } from "../src/pages/users";
import ShowUser from "./pages/users/show-user";
import UsersEdit from "./pages/users/edit-user";
import { EditTransaction, ListTransactions, ShowTransaction } from "./pages/transactions";

const icon = {
  className: "w-5 h-5 text-inherit",
};

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: "Dashboard",
        path: "/home",
        element: <Home />,
        visibleOnSidebar: true,
      },
    ],
  },
  {
    layout: "users",
    pages: [
      {
        icon: <TableCellsIcon {...icon} />,
        name: "Utilisateurs",
        path: "/list",
        element: <ListUsers />,
        visibleOnSidebar: true,
      },
      {
        icon: <EyeIcon {...icon} />,
        name: "Voir un utilisateur",
        path: "/:id/show",
        element: <ShowUser />,
        visibleOnSidebar: false,
      },
      {
        icon: <PencilIcon {...icon} />,
        name: "Modifier un utilisateur",
        path: "/:id/edit",
        element: <UsersEdit />,
        visibleOnSidebar: false,
      },
    ],
  },
  {
    layout: "transactions",
    pages: [
      {
        icon: <Bars4Icon {...icon} />,
        name: "Transactions",
        path: "/list",
        element: <ListTransactions />,
        visibleOnSidebar: true,
      },
      {
        icon: <EyeIcon {...icon} />,
        name: "Voir une transaction",
        path: "/:id/show",
        element: <ShowTransaction />,
        visibleOnSidebar: false,
      },
      {
        icon: <PencilIcon {...icon} />,
        name: "Modifier une transaction",
        path: "/:id/edit",
        element: <EditTransaction />,
        visibleOnSidebar: false,
      },
    ],
  },
];

export default routes;
