import axios from "axios";

const usersApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/users`;

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface SignUpResponse {
  id: string;
  email: string;
}

export interface LoginFailedResponse {
  user: {
    id: string;
    email: string;
  };
  messageCode: string;
}

export interface LoginResponse {
  user: {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
  };
  accessToken: string;
}

export const signUp = async ({ firstName, lastName, email, password }: SignUpRequest) => {
  const signUpResponse = await axios.post(`${usersApiUrl}/admin/sign-up`, {
    firstName,
    lastName,
    email,
    password,
  });

  return signUpResponse.data as SignUpResponse;
};

export const login = async ({ email, password }: LoginRequest): Promise<LoginResponse> => {
  const loginResponse = await axios.post(`${usersApiUrl}/admin/login`, { email, password });
  if (loginResponse.data.accessToken) {
    localStorage.setItem("user", JSON.stringify(loginResponse.data))
  }

  return loginResponse.data;
};

export const logout = () => {
  localStorage.removeItem("user");
};