import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/auth.service';

export function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate("/login", { replace: true });
  }, [navigate]);

  return (<></>);
}

export default Logout;