import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button,
  Chip,
} from '@material-tailwind/react';
import { AxiosError } from 'axios';
import { getUser } from '../../services/user.service';
import { GetUserResponse } from '../../services/response/get-user.response';
import { formatDate, formatDateTime } from '../../utils/helpers/date.helpers';
import { getGenderLabel } from '../../utils/helpers/get-gender-label';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { PencilIcon, TableCellsIcon } from '@heroicons/react/24/solid';
import { KycVerificationStatus } from '../../enums/kyc-verification-status.enum';
import { colors } from '@material-tailwind/react/types/generic';

export function ShowUser() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [user, setUser] = useState<GetUserResponse | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      setErrorMessage('L\'identifiant de l\'utilisateur est manquant.');
      return;
    };

    const fetchUser = async () => {
      try {
        const getUserResponse = await getUser(id);
        setUser(getUserResponse);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération des informations sur l"utilisateur.';
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
      }
    };

    fetchUser();
  }, [id]);

  const kycVerificationStatus = user?.userKycInfo?.verificationStatus ? user?.userKycInfo?.verificationStatus : KycVerificationStatus.NOT_STARTED;
  let kycVerificationStatusColor;
  switch (kycVerificationStatus) {
    case KycVerificationStatus.PENDING:
      kycVerificationStatusColor = "orange";
      break;
    case KycVerificationStatus.APPROVED:
      kycVerificationStatusColor = "green";
      break;
    case KycVerificationStatus.REJECTED:
      kycVerificationStatusColor = "red";
      break;
    default:
      kycVerificationStatusColor = "blue-gray";
      break;
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="amber" className="mb-4 p-6">
          <Typography variant="h5" color="white" className="text-center">
            Détails de l'utilisateur
          </Typography>
        </CardHeader>
        <CardBody className="px-0 pt-0 pb-2">
          <div className="flex justify-end gap-4 mb-4">
            <Button
              color="amber"
              size="sm"
              variant="outlined"
              className="py-1 px-2 flex items-center gap-1"
              onClick={() => {
                navigate(`/users/${id}/edit`);
              }}
            >
              <PencilIcon className="h-4 w-4" />Modifier l'utilisateur
            </Button>
            <Button
              color="amber"
              size="sm"
              variant="outlined"
              className="py-1 px-2 flex items-center gap-1"
              onClick={() => {
                // Add logic to view user transactions
              }}
            >
              <TableCellsIcon className="h-4 w-4" />Voir les transactions de l'utilisateur
            </Button>
          </div>
          {errorMessage && (
            <div className="text-base text-center text-red-500">
              {errorMessage}
            </div>
          )}
          <Tabs value="profile">
            <TabsHeader>
              <Tab key="profile" value="profile">
                Profil
              </Tab>
              <Tab key="kyc-info" value="kyc-info">
                Informations de vérification KYC
              </Tab>
              <Tab key="registration-progress" value="registration-progress">
                Statut de l'inscription
              </Tab>
            </TabsHeader>
            <TabsBody>
              <TabPanel key="profile" value="profile">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Actif:
                    </Typography>
                    <Typography>
                      {user?.active && "oui"}
                      {!user?.active && "non"}
                    </Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Email:
                    </Typography>
                    <Typography>{user?.email}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Date de création:
                    </Typography>
                    <Typography>{user?.createdAt && formatDateTime(user?.createdAt)}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Prénom:
                    </Typography>
                    <Typography>{user?.userProfile?.firstName}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Nom:
                    </Typography>
                    <Typography>{user?.userProfile?.lastName}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Pays de la nationalité:
                    </Typography>
                    <Typography>{user?.userProfile?.nationalityCountry.name}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Numéro de téléphone:
                    </Typography>
                    <Typography>{user?.userProfile?.phoneNumberValue}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Genre:
                    </Typography>
                    <Typography>{user?.userProfile?.gender && getGenderLabel(user?.userProfile?.gender)}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Date de naissance:
                    </Typography>
                    <Typography>{user?.userProfile?.birthdate && formatDate(user?.userProfile?.birthdate)}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Pays de résidence:
                    </Typography>
                    <Typography>{user?.userProfile?.userAddress?.country?.name}</Typography>
                  </div>
                </div>
              </TabPanel>
              <TabPanel key="kyc-info" value="kyc-info">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Type du document d'identité:
                    </Typography>
                    <Typography>{user?.userKycInfo?.idDocumentType}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Pays de délivrance du document d'identité:
                    </Typography>
                    <Typography>{user?.userKycInfo?.idDocumentIssuingCountry.name}</Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Numéro du document d'identité:
                    </Typography>
                    <Typography>{user?.userKycInfo?.idDocumentNumber}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Status de vérification KYC:
                    </Typography>
                    <Typography><Chip
                      variant="gradient"
                      color={kycVerificationStatusColor as colors}
                      value={kycVerificationStatus as string}
                      className="py-0.5 px-2 text-[11px] font-medium"
                    /> </Typography>
                  </div>

                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Recapitulatif de la vérification KYC par SmileIdentity:
                    </Typography>
                    {user?.userKycInfo?.smileIdentityKycReceipt && (
                      <Link to={user.userKycInfo.smileIdentityKycReceipt} target="_blank">
                        <Typography className="text-amber-600 hover:underline">
                          Voir le récapitulatif de la vérification KYC
                        </Typography>
                      </Link>
                    )}
                  </div>

                </div>
              </TabPanel>
              <TabPanel key="registration-progress" value="registration-progress">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Email vérifié:
                    </Typography>
                    <Typography>{user?.userRegistrationProgress?.isEmailVerified === true ? 'oui' : 'non'}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Première partie des informations de profil complétée:
                    </Typography>
                    <Typography>{user?.userRegistrationProgress?.isProfilePartOneCompleted === true ? 'oui' : 'non'}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Seconde partie des informations de profil complétée:
                    </Typography>
                    <Typography>{user?.userRegistrationProgress?.isProfilePartTwoCompleted === true ? 'oui' : 'non'}</Typography>
                  </div>
                  <div className="w-full lg:w-1/2 mb-4">
                    <Typography className="font-semibold">
                      Vérification KYC complétée:
                    </Typography>
                    <Typography>{user?.userRegistrationProgress?.isKycCompleted === true ? 'oui' : 'non'}</Typography>
                  </div>
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </CardBody>
      </Card>
    </div>
  );
}

export default ShowUser;
