import React from 'react';
import { ErrorMessage } from 'formik';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

export interface PhoneNumberCountry {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

function PhoneNumberField(props: any) {
  const { field, form } = props;

  function handleOnChange(value: string, country: PhoneNumberCountry) {
    form.setFieldValue(field.name, value);
    form.setFieldValue('phoneNumberCountry', country);
  }

  function handleOnBlur() {
    form.setFieldTouched(field.name, true);
  }

  return (
    <div>
      <PhoneInput
        {...field}
        {...props}
        value={field.value}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
      />
    </div>
  );
}

export default PhoneNumberField;